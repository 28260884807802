.btn-primary{
    @include center-row();
    background-color: $color-primary;
    border-radius: 24px;
    padding: 8px 24px !important;
    transition: 0.3s;
    border: none;
    &:hover{
        background-color: $color-primary-hover;
        transition: 0.3s;
        color: $color-white !important;
    }
}

.btn-secondary{
    background-color: $color-secondary;
    border-radius: 24px;
    padding: 8px 24px !important;
    transition: 0.3s;
    border: none;

    &:hover{
        background-color: $color-secondary-hover;
        transition: 0.3s;
        color: $color-white !important;
        background-position: 0 100%;
    }
}

.btn-tertiary{
    background-color: transparent;
    border-radius: 24px;
    padding: 8px 24px !important;
    transition: 0.3s;
    border: 2px solid $color-secondary;
    color: $color-secondary;

    &:hover{
        background-color: $color-secondary20;
        transition: 0.3s;
        color: $color-secondary;
        background-position: 0 100%;
    }
}