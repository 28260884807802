@media (min-width: 1200px) {
    .prodi-thumbnail{
        max-width: 200px;
    }
}

@media (min-width: 1600px) {
    .reward {
        .reward-item {
            padding: 0 100px !important;
        }
    }
}