footer{
    background-repeat: no-repeat !important;
    background-size: cover !important;
    min-height: 300px;
    color: $color-white;
    margin: 0;
    padding: 0;
    font-size: 14px;

    ul{
        margin-top: 16px;
        li{
            list-style: none;
            line-height: 1.8em;
            a{
                position: relative;
                display: inline-block;
                color: royalblue;
                overflow: hidden;
                background: linear-gradient(to right, $color-secondary, $color-secondary-hover 50%, $color-white 50%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-size: 200% 100%;
                background-position: 100%;
                transition: background-position 275ms ease;
                text-decoration: none;
            
                &:hover {
                    background-position: 0 100%;
                }
            }
        }
    }
    .fax{
        margin-top: -10px;
    }

    .footer-col{
        min-height: 300px;
    }

    hr{
        border: 1px solid $color-line;
    }
}