.splide-overlay{
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.6) 46.43%, rgba(0, 0, 0, 0.2) 100%);
    visibility: visible;
    opacity: 0.7;
    transition: visibility 0s, opacity 0.3s linear;
}

.splide {
    &:hover {
        .splide-overlay{
            visibility: visible;
            opacity: 1;
        }
    }
}

.splide__arrow{
    display: none;
}

.splide__slide{
    .splide-desc{
        @include center-column();
        position: absolute;
        z-index: 1;
        background-color: rgba(0, 0, 0, 0);
        width: 100%;
        height: 100%;
        color: $color-white;

        h2{
            color: $color-white !important;
            font-weight: 300;
        }
    }

    .splide-play{
        @include center-column();
        width: 80px;
        height: 80px;
        border-radius: 40px;
        border: 0.1px solid $color-white;
        transition: 0.3s;
        
        &:hover{
            cursor: pointer;
            transform: scale(1.1);
            transition: 0.3s;
        }
    }

    a{
        color: $color-white;
        &:hover{
            text-decoration: none;
        }
    }
}

.splide__pagination{
    z-index: 0;
}