$color-white : #FFFFFF;
$color-white-hover : #c7c7c7;
$color-white-hover-soft : #eeeeee;

$color-primary : #1C59B4;
$color-primary-hover : #154ea3;
$color-primary10 : #1C59B41a;
$color-primary20 : #1C59B433;
$color-primary-light : #1264DE;
$color-primary-dark : #013179;
$color-primary-dark90 : #013179f2;
$color-primary-dark110 : #002c6d;
$color-primary-dark-border : #00379e;

$color-secondary : #78BC4E;
$color-secondary20 : #78BC4E33;
$color-secondary30 : #78BC4E4D;
$color-secondary-hover : #6b9c4c;

$color-black : #373435;
$color-black5 : #3734350d;
$color-black50 : #37343580;
$color-black65 : #373435A6;
$color-black80 : #373435f3;

$color-red : #E94038;
$color-blue : #2A5792;
$color-yellow : #FDCA33;

$color-overlay : #12233Ccc;

$color-line : #FFFFFF33;