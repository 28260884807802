.profile-content {
	padding-top: 140px;
	padding-bottom: 140px;

	.right {
		border-left: 1px solid $color-primary10 !important;
	}

	.overview-tab {
		padding: 0 !important;
		box-shadow: 10px 20px 30px rgba(0, 0, 0, 0.05);
		min-height: 200px;
		border: 1px solid $color-primary10 !important;

		.nav-tabs {
			border: 1px solid $color-line !important;
		}

		.nav-link {
			background-color: $color-black5 !important;
			color: $color-black65 !important;
			border-radius: 0 !important;
			padding: 16px 32px !important;
			border: 1px solid $color-line !important;
		}

		.active {
			background-color: $color-white !important;
			color: $color-black !important;
		}
	}
}

.nav-pills {
	.nav-link {
		color: $color-black50 !important;
		margin-bottom: 16px !important;
	}

	.active {
		background-color: $color-primary20 !important;
		color: $color-primary !important;
		padding: 16px 20px !important;
		border-radius: 0 32px 32px 0 !important;

		.nav-link {
			border-radius: 100px;
		}
	}
}

.overview-tab {
	.left {
		span, p {
			color: $color-black50;
		}
	}
}

@mixin circle {
	width: 30px;
	height: 30px;
	border-radius: 20px;
}

.circle-green {
	@include circle();
	background-color: $color-secondary;
}

.circle-red {
	@include circle();
	background-color: $color-red;
}

.circle-yellow {
	@include circle();
	background-color: $color-yellow;
}
.circle-blue {
	@include circle();
	background-color: $color-blue;
}

.circle-black {
	@include circle();
	background-color: $color-black;
}

.visi-misi {
	padding: 40px;
	height: auto;
	box-sizing: border-box;
	box-shadow: 10px 20px 30px rgba(0, 0, 0, 0.05);
}

.restra {
	.card-header {
		background-color: $color-white !important;
		border-bottom: 1px solid $color-primary10 !important;
	}

	.card-body {
		background: $color-white;
		box-shadow: 10px 20px 30px rgba(0, 0, 0, 0.05);

		.upload-date {
			color: $color-black50;
		}
	}

	.more {
		&:hover {
			cursor: pointer;
		}
	}
}


//orgstruktur
.organization-structure {
	* {
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		position: relative;
	}

	.cf:before,
	.cf:after {
		content: " ";
		/* 1 */
		display: table;
		/* 2 */
	}

	.cf:after {
		clear: both;
	}

	/**
	 * For IE 6/7 only
	 * Include this rule to trigger hasLayout and contain floats.
	 */
	.cf {
		*zoom: 1;
	}

	/* Generic styling */


	.content {
		width: 100%;
		max-width: 1142px;
		margin: 0 auto;
		padding: 0 20px;
	}

	a:focus {
		outline: 2px dashed #f7f7f7;
	}

	@media screen and (max-width: 767px) {
		.content {
			padding: 0 20px;
		}
	}

	ul {
		padding: 0;
		margin: 0;
		list-style: none;
	}

	ul a {
		display: block;
		background: #ccc;
		border: 4px solid #fff;
		text-align: center;
		overflow: hidden;
		font-size: .7em;
		text-decoration: none;
		font-weight: bold;
		color: #333;
		height: 70px;
		margin-bottom: -26px;
		box-shadow: 4px 4px 9px -4px rgba(0, 0, 0, 0.4);
		-webkit-transition: all linear .1s;
		-moz-transition: all linear .1s;
		transition: all linear .1s;
	}

	@media print {
		ul a {
			border: 4px solid #000;
		}
	}

	@media screen and (max-width: 767px) {
		ul a {
			font-size: 1em;
		}
	}


	ul a span {
		top: 50%;
		margin-top: -0.7em;
		display: block;
	}

	/*
	 
	 */

	.administration>li>a {
		margin-bottom: 25px;
	}

	.director>li>a {
		width: 50%;
		margin: 0 auto 0px auto;
	}

	.subdirector:after {
		content: "";
		display: block;
		width: 0;
		height: 130px;
		background: red;
		border-left: 4px solid #fff;
		left: 45.45%;
		position: relative;
	}

	@media print {
		.subdirector:after {
			border-left: 4px solid #000;
		}
	}

	.subdirector,
	.departments {
		position: absolute;
		width: 100%;
	}

	.subdirector>li:first-child,
	.departments>li:first-child {
		width: 18.59894921190893%;
		height: 64px;
		margin: 0 auto 92px auto;
		padding-top: 25px;
		border-bottom: 4px solid white;
		z-index: 1;
	}

	@media print {

		.subdirector>li:first-child,
		.departments>li:first-child {
			border-bottom: 4px solid #000;
		}
	}

	.subdirector>li:first-child {
		float: right;
		right: 27.2%;
		border-left: 4px solid white;
	}

	@media print {
		.subdirector>li:first-child {
			border-left: 4px solid black;
		}
	}

	.departments>li:first-child {
		float: left;
		left: 27.2%;
		border-right: 4px solid white;
	}

	@media print {
		.departments>li:first-child {
			border-right: 4px solid black;
		}
	}

	.subdirector>li:first-child a,
	.departments>li:first-child a {
		width: 100%;
	}

	.subdirector>li:first-child a {
		left: 25px;
	}

	@media screen and (max-width: 767px) {

		.subdirector>li:first-child,
		.departments>li:first-child {
			width: 40%;
		}

		.subdirector>li:first-child {
			right: 10%;
			margin-right: 2px;
		}

		.subdirector:after {
			left: 49.8%;
		}

		.departments>li:first-child {
			left: 10%;
			margin-left: 2px;
		}
	}


	.departments>li:first-child a {
		right: 25px;
	}

	.department:first-child,
	.departments li:nth-child(2) {
		margin-left: 0;
		clear: left;
	}

	.departments:after {
		content: "";
		display: block;
		position: absolute;
		width: 81.1%;
		height: 22px;
		border-top: 4px solid #fff;
		border-right: 4px solid #fff;
		border-left: 4px solid #fff;
		margin: 0 auto;
		top: 130px;
		left: 9.1%
	}

	@media print {
		.departments:after {
			border-top: 4px solid #000;
			border-right: 4px solid #000;
			border-left: 4px solid #000;
		}
	}

	@media screen and (max-width: 767px) {
		.departments:after {
			border-right: none;
			left: 0;
			width: 49.8%;
		}
	}

	@media screen and (min-width: 768px) {

		.department:first-child:before,
		.department:last-child:before {
			border: none;
		}
	}

	.department:before {
		content: "";
		display: block;
		position: absolute;
		width: 0;
		height: 22px;
		border-left: 4px solid white;
		z-index: 1;
		top: -22px;
		left: 50%;
		margin-left: -4px;
	}

	@media print {
		.department:before {
			border-left: 4px solid black;
		}
	}

	.department {
		border-left: 4px solid #fff;
		width: 18.59894921190893%;
		float: left;
		margin-left: 1.751313485113835%;
		margin-bottom: 60px;
	}

	@media print {
		.department {
			border-left: 4px solid #000;
		}
	}

	.lt-ie8 .department {
		width: 18.25%;
	}

	@media screen and (max-width: 767px) {
		.department {
			float: none;
			width: 100%;
			margin-left: 0;
		}

		.department:before {
			content: "";
			display: block;
			position: absolute;
			width: 0;
			height: 60px;
			border-left: 4px solid white;
			z-index: 1;
			top: -60px;
			left: 0%;
			margin-left: -4px;
		}

		.department:nth-child(2):before {
			display: none;
		}
	}

	.department>a {
		margin: 0 0 -26px -4px;
		z-index: 1;
	}

	.department>a:hover {
		height: 80px;
	}

	.department>ul {
		margin-top: 0px;
		margin-bottom: 0px;
	}

	.department li {
		padding-left: 25px;
		border-bottom: 4px solid #fff;
		height: 80px;
	}

	@media print {
		.department li {
			border-bottom: 4px solid #000;
		}
	}

	.department li a {
		background: #fff;
		top: 48px;
		position: absolute;
		z-index: 1;
		width: 90%;
		height: 60px;
		vertical-align: middle;
		right: -1px;
		background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMTAwJSI+CiAgICA8c3RvcCBvZmZzZXQ9IjAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMjUiLz4KICAgIDxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIwIi8+CiAgPC9saW5lYXJHcmFkaWVudD4KICA8cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2dyYWQtdWNnZy1nZW5lcmF0ZWQpIiAvPgo8L3N2Zz4=);
		background-image: -moz-linear-gradient(-45deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 100%) !important;
		background-image: -webkit-gradient(linear, left top, right bottom, color-stop(0%, rgba(0, 0, 0, 0.25)), color-stop(100%, rgba(0, 0, 0, 0))) !important;
		background-image: -webkit-linear-gradient(-45deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 100%) !important;
		background-image: -o-linear-gradient(-45deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 100%) !important;
		background-image: -ms-linear-gradient(-45deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 100%) !important;
		background-image: linear-gradient(135deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 100%) !important;
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#40000000', endColorstr='#00000000', GradientType=1);
	}

	.department li a:hover {
		box-shadow: 8px 8px 9px -4px rgba(0, 0, 0, 0.1);
		height: 80px;
		width: 95%;
		top: 39px;
		background-image: none !important;
	}

	/* Department/ section colors */
	.department.dep-a a {
		background: #FFD600;
	}

	.department.dep-b a {
		background: #AAD4E7;
	}

	.department.dep-c a {
		background: #FDB0FD;
	}

	.department.dep-d a {
		background: #A3A2A2;
	}

	.department.dep-e a {
		background: #f0f0f0;
	}

}

.calendar {
	display: grid;
	width: 100%;
	grid-template-columns: repeat(7, minmax(120px, 1fr));
	grid-template-rows: 50px;
	grid-auto-rows: 120px;
	overflow: auto;

	&-container {
		width: 90%;
		margin: auto;
		overflow: hidden;
		box-shadow: 0 2px 20px rgba(0, 0, 0, 0.1);
		border-radius: 10px;
		background: #fff;
		max-width: 1200px;
	}

	&-header {
		text-align: center;
		padding: 20px 0;
		background: linear-gradient(to bottom, rgb(250, 251, 253) 0%, rgba(255, 255, 255, 0) 100%);
		border-bottom: 1px solid rgba(166, 168, 179, 0.12);

		h1 {
			margin: 0;
			font-size: 18px;
		}

		p {
			margin: 5px 0 0 0;
			font-size: 13px;
			font-weight: 600;
			color: rgba(#51565d, .4);
		}

		button {
			background: 0;
			border: 0;
			padding: 0;
			color: rgba(#51565d, .7);
			cursor: pointer;
			outline: 0;
		}
	}
}

.day {
	border-bottom: 1px solid rgba(166, 168, 179, 0.12);
	border-right: 1px solid rgba(166, 168, 179, 0.12);
	text-align: right;
	padding: 14px 20px;
	letter-spacing: 1px;
	font-size: 12px;
	box-sizing: border-box;
	color: #98a0a6;
	position: relative;
	pointer-events: none;
	z-index: 1;

	&:nth-of-type(7n + 7) {
		border-right: 0;
	}

	&:nth-of-type(n + 1):nth-of-type(-n + 7) {
		grid-row: 2;
	}

	&:nth-of-type(n + 8):nth-of-type(-n + 14) {
		grid-row: 3;
	}

	&:nth-of-type(n + 15):nth-of-type(-n + 21) {
		grid-row: 4;
	}

	&:nth-of-type(n + 22):nth-of-type(-n + 28) {
		grid-row: 5;
	}

	&:nth-of-type(n + 29):nth-of-type(-n + 35) {
		grid-row: 6;
	}

	&:nth-of-type(7n + 1) {
		grid-column: 1/1;
	}

	&:nth-of-type(7n + 2) {
		grid-column: 2/2;
	}

	&:nth-of-type(7n + 3) {
		grid-column: 3/3;
	}

	&:nth-of-type(7n + 4) {
		grid-column: 4/4;
	}

	&:nth-of-type(7n + 5) {
		grid-column: 5/5;
	}

	&:nth-of-type(7n + 6) {
		grid-column: 6/6;
	}

	&:nth-of-type(7n + 7) {
		grid-column: 7/7;
	}

	&-name {
		font-size: 12px;
		text-transform: uppercase;
		color: #99a1a7;
		text-align: center;
		border-bottom: 1px solid rgba(166, 168, 179, 0.12);
		line-height: 50px;
		font-weight: 500;
	}

	&--disabled {
		color: rgba(#98a0a6, 0.6);
		background-color: #ffffff;
		background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23f9f9fa' fill-opacity='1' fill-rule='evenodd'%3E%3Cpath d='M0 40L40 0H20L0 20M40 40V20L20 40'/%3E%3C/g%3E%3C/svg%3E");
		cursor: not-allowed;
	}
}

.task {
	border-left-width: 3px;
	padding: 8px 12px;
	margin: 10px;
	border-left-style: solid;
	font-size: 14px;
	position: relative;

	&--warning {
		border-left-color: #fdb44d;
		grid-column: 4 / span 3;
		grid-row: 3;
		background: #fef0db;
		align-self: center;
		color: darken(#fdb44d, 12%);
		margin-top: -5px;
	}

	&--danger {
		border-left-color: #fa607e;
		grid-column: 2 / span 3;
		grid-row: 3;
		margin-top: 15px;
		background: rgba(#fdc5d0, 0.7);
		align-self: end;
		color: darken(#fa607e, 12%);
	}

	&--info {
		border-left-color: #4786ff;
		grid-column: 6 / span 2;
		grid-row: 5;
		margin-top: 15px;
		background: rgba(#dae7ff, 0.7);
		align-self: end;
		color: darken(#4786ff, 12%);
	}

	&--primary {
		background: #4786ff;
		border: 0;
		border-radius: 4px;
		grid-column: 3 / span 3;
		grid-row: 4;
		align-self: end;
		color: #fff;
		box-shadow: 0 10px 14px rgba(#4786ff, 0.4);
	}

	&__detail {
		position: absolute;
		left: 0;
		top: calc(100% + 10px);
		background: #fff;
		border: 1px solid rgba(166, 168, 179, 0.2);
		color: #000;
		padding: 20px;
		box-sizing: border-box;
		border-radius: 4px;
		box-shadow: 0 10px 40px rgba(0, 0, 0, 0.08);
		z-index: 2;

		&:after,
		&:before {
			bottom: 100%;
			left: 30%;
			border: solid transparent;
			content: " ";
			height: 0;
			width: 0;
			position: absolute;
			pointer-events: none;
		}

		&:before {
			border-bottom-color: rgba(166, 168, 179, 0.2);
			border-width: 8px;
			margin-left: -8px;
		}

		&:after {
			border-bottom-color: #fff;
			border-width: 6px;
			margin-left: -6px;
		}

		h2 {
			font-size: 15px;
			margin: 0;
			color: #51565d;
		}

		p {
			margin-top: 4px;
			font-size: 12px;
			margin-bottom: 0;
			font-weight: 500;
			color: rgba(#51565d, .7);
		}
	}
}

#chart-container {
	font-family: Arial;
	height: 420px;
	border: 2px dashed #aaa;
	border-radius: 5px;
	overflow: auto;
	text-align: center;
}

.rotated-image {
	-webkit-transform: rotate(20deg);
	transform: rotate(20deg);
}

.rotate-show {
	transform: rotate(90deg) !important;
	transition: .3s !important;
}

.rotate-hidden {
	transform: rotate(0deg) !important;
	transition: .3s !important;
}

 .svg-chart-container{
	 width: 100%;
	 height: 80vh;
	 background-color: $color-primary10 !important;
 }

 #calendar{
	border: 1px solid #e5e5e5;
 }